export interface ProductCategory {
    pcn:string;
    icon:string;
    display:string;
}

const productCategories = [
    {
        pcn:'Laptops', 
        icon:'icons/laptop.png', 
        display:'slide'
    }, 
    {
        pcn:'Desktops', 
        icon:'icons/desktop.png', 
        display:'slide'
    },
    {
        pcn:'WorkStations', 
        icon:'icons/workstation.png', 
        display:'noSlide'
    }, 
    {
        pcn:'Monitors', 
        icon:'icons/monitor.png', 
        display:'slide'
    }, 
    {
        pcn:'Servers', 
        icon:'icons/server.png', 
        display:'noSlide'
    }, 
    {
        pcn:'Electronics', 
        icon:'icons/electronic.png', 
        display:'slide'
    }
];

export default productCategories;