import React, {useRef, useEffect} from 'react';
import ProductCategorySection from './ProductCategorySection';
import ProductSectionArea from './ProductSectionArea';
import {window} from './window';
import './SliderWrap.css';

interface Props{}

const SliderWrap:React.FunctionComponent<Props> = (props:Props)=> {
    const productCategorySectionRefs = useRef<HTMLUListElement[]>([]);
    const productSectionContainers = useRef<HTMLDivElement[]>([]);
    const sliderWrapRef = useRef<HTMLDivElement>(null);
    const start = useRef(0);
    const f1 = useRef(0);
    const f2 = useRef(0);

    function step(timestamp:DOMHighResTimeStamp):void {
        //console.log('scroll');
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        
        if(elapsed < 300){
             f2.current = window.requestAnimationFrame(step);
        } else {
            sliderWrapRef.current!.classList.remove('hide');
            window.cancelAnimationFrame(f2.current);
            window.addEventListener('scroll', moveupHandler);

        }
    }

    function setupProductContainerMovement(pic:HTMLDivElement):void {
        const eh = pic.getBoundingClientRect().top
        //console.log('pic eh:', eh);
        if(!pic.classList.contains('move-container-up') && eh < window.innerHeight && window.matchMedia('(min-width:700px)').matches){
            pic.classList.add('move-container-up');                
        }
    }

    function moveupHandler(e:Event):void {
        //console.log('scroll 12-30:', window.pageYOffset);
        f1.current = window.requestAnimationFrame((timestamp:DOMHighResTimeStamp)=>{
            makeSupportSectionSticky(productCategorySectionRefs.current[0]);
            for(let i=0; i < productSectionContainers.current.length; i++){
                setupProductContainerMovement(productSectionContainers.current[i]);
            }
            window.cancelAnimationFrame(f1.current);
            currentPosition();
        });
    }

    function makeSupportSectionSticky(pcs:HTMLUListElement):void {
        const pcsTopPosition = pcs.getBoundingClientRect().top;
        //console.log('pcs top:', pcsTopPosition);
        //console.log('wt:', window.pageYOffset);
        if(pcsTopPosition < 10 && window.matchMedia('(min-width:700px)').matches){
            //console.log('sticky passed');
            pcs.classList.add('sticky');
        } else {
            //console.log('sticky failed');
            pcs.classList.remove('sticky');
        }
    }

    function cleanupMoveupEventHandler(){
        //window.location.reload();
        return window.removeEventListener('scroll', moveupHandler);
    }

    //timestamp:DOMHighResTimeStamp
    function currentPosition():void {
        //const doc = document.documentElement;
        //window.sessionStorage.setItem('scrollTop', (doc.scrollTop).toString());
        window.sessionStorage.setItem('scrollTop', (window.pageYOffset).toString());
    }
    
    useEffect(()=>{
        //f1.current = window.requestAnimationFrame(step);
        //original version
        window.addEventListener('scroll', moveupHandler);
        //console.log('productCategorySectionRefs:', productCategorySectionRefs);
        //console.log('productSectionContainers:', productSectionContainers);
        return cleanupMoveupEventHandler;
    });

    return (
        <div ref={sliderWrapRef} className="middle-nav-bar">
            <ProductCategorySection productCategorySectionRefs={productCategorySectionRefs}/>
            <ProductSectionArea productSectionContainers={productSectionContainers}/>
            <div className="footer"></div>
        </div>
    );
}

export default SliderWrap;