import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import addOverlay, {Overlay, SearchBox, addSearchBox} from './redux/actions';
import countryList from './country-list';
import './AdditionalOptions.css';
//import { clearTimeout } from 'timers';

interface State {
    showOverlay:boolean;
    showSearchBox:boolean;
}

function mapStateToProps(state:State):State {
    const {showOverlay, showSearchBox} = state;
    return {
        showOverlay,
        showSearchBox
    };
}

const mapDispatchToProps = { addOverlay, addSearchBox };

interface Props {
    showOverlay:boolean;
    showSearchBox:boolean;
    addOverlay:(showOverlay:boolean)=>Overlay;
    addSearchBox:(showSearchBox:boolean)=>SearchBox;
}

const AdditionalOptions:React.FunctionComponent<Props> = (props:Props) => {
    //console.log('AO showOverlay:', props.showOverlay);
    const {addOverlay, addSearchBox} = props;
    const [countries, setCountries] = useState(countryList);
    const [showCountryList, setShowCountryList] = useState(false);
    const menuOptionBtnContainer = useRef<HTMLDivElement>(null);
    const [showOverlay, setShowOverlay] = useState(props.showOverlay);
    const [showSearchBox, setShowSearchBox]= useState(props.showSearchBox);

    useEffect(()=>{
        setShowOverlay(props.showOverlay);
    },[props.showOverlay]);

    useEffect(()=>{
        setShowSearchBox(props.showSearchBox);
    },[props.showSearchBox]);

    function addCountryListHandler(e:MouseEvent):void {
        //console.log('document event aclh:', e.target);
        setShowCountryList(false);
    }

    function cleanupCountryListHandler():void {
        document.removeEventListener('click', addCountryListHandler);
    }

    useEffect(()=>{
        document.addEventListener('click', addCountryListHandler);
        return cleanupCountryListHandler;
    },[]);

    function countryListHandler(e:React.MouseEvent):void {
        e.nativeEvent.stopPropagation();
        setShowCountryList(!showCountryList);
    }

    function menuBtnOptionHandler(e:React.MouseEvent):void {
        //console.log('click:', e);
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        //console.log('clicked:', document.body.style.marginRight = '-17px');
        const ct = menuOptionBtnContainer.current as HTMLDivElement;//e.currentTarget as HTMLDivElement;
        //const app = (((ct.parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement;
        //const header = app.firstElementChild as HTMLDivElement;
        //console.log('h:', header);
        //const mc = (header.nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        //const mnb = (mc.nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        //console.log('mvb:', mvb);
        const mobilePanel = (((ct.parentElement as HTMLDivElement).parentElement as HTMLDivElement).nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDListElement;
        //const promotion = ((app.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        
        if(mobilePanel.classList.contains('increase-mp-width')
            && document.body.classList.contains('move-app-left')){
            document.body.classList.remove('move-app-left');
            mobilePanel.classList.remove('increase-mp-width');


        } else {

            document.body.classList.add('move-app-left');
            mobilePanel.classList.add('increase-mp-width');
        }

        addOverlay(!showOverlay);
        
    }

    function menuBtnOptionTouchHandler(e:React.TouchEvent):void {
        //console.log('touchend:', e.changedTouches);
        e.preventDefault();
        //e.stopPropagation();
        //e.nativeEvent.stopImmediatePropagation();
        //console.log('clicked:', document.body.style.marginRight = '-17px');
        const ct = menuOptionBtnContainer.current as HTMLDivElement;//e.currentTarget as HTMLDivElement;
        //const app = (((ct.parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement;
        //const header = app.firstElementChild as HTMLDivElement;
        //console.log('h:', header);
        //const mc = (header.nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        //const mnb = (mc.nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        //console.log('mvb:', mvb);
        const mobilePanel = (((ct.parentElement as HTMLDivElement).parentElement as HTMLDivElement).nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDListElement;
        //console.log('mp:', mobilePanel);
        //const promotion = ((app.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement).nextElementSibling as HTMLDivElement;
        if(mobilePanel.classList.contains('increase-mp-width')
            && document.body.classList.contains('move-app-left')){
            document.body.classList.remove('move-app-left');
            mobilePanel.classList.remove('increase-mp-width');


        } else {

            document.body.classList.add('move-app-left');
            mobilePanel.classList.add('increase-mp-width');
        }

        addOverlay(!showOverlay);
    }

    function setupCountryList(countryList:string[]):JSX.Element{
        return <ul className="country-list">
                    {countryList.map((cn, i)=>{
                        return <li className="country-list-item" key={i}>
                                    <a className="country-list-item-link" href="/">{cn}</a>
                                </li>
                    })}
                </ul>
    }

    function showSearchBoxTouchHandler(e:React.TouchEvent):void {
        e.preventDefault();
        //console.log('search test touch');
        addSearchBox(!showSearchBox);
    }

    function showSearchBoxClickHandler(e:React.MouseEvent):void {
        e.stopPropagation();
        //console.log('search test click');
        addSearchBox(!showSearchBox);
    }

    return (
      <div className="right-col">
            <div className="right-btn-option">
              <button data-testid="search-btn" type="button" className="search-btn"
                onClick={showSearchBoxClickHandler}
                onTouchEnd={showSearchBoxTouchHandler}>
                  <span className="search-icon-container"><i className="fa fa-search sign-in-icon" aria-hidden="true"></i></span>
                  <span className="search-text">Search</span>
              </button>
          </div>
          <div className="right-btn-option">
              <button data-testid="sign-in-btn" type="button" className="sign-in-btn">
                  <span className="sign-in-icon-container"><i className="fa fa-user sign-in-icon" aria-hidden="true"></i></span>
                  <span className="sign-in-text">Sign In</span>
              </button>
          </div>
          <div className="right-btn-option">
              <a href="/" role="button" className="shopping-cart-icon-link">
                  <span className="shopping-cart-icon-container"><i className="fa fa-shopping-cart shopping-cart-icon" aria-hidden="true"></i></span>
                  <span className="shopping-cart-text">Cart</span>
              </a>
          </div>
          <div className="right-btn-option" onClick={countryListHandler}>
              <span className="country-text">USA</span>
              {showCountryList ? setupCountryList(countries) : null}
          </div>
          <div className="right-btn-menu-option" ref={menuOptionBtnContainer}>
              <div className="menu-btn-container">
                  <button type="button" className="menu-btn" onClick={menuBtnOptionHandler} onTouchEnd={menuBtnOptionTouchHandler}>
                    <span className="menu-btn-icon-container">
                        <i className="fa fa-bars menu-btn-icon" aria-hidden="true"></i>
                    </span>
                    <span className="menu-btn-text">Menu</span>
                  </button>
              </div>
          </div>
      </div>
    );
  }
  
  //export default AdditionalOptions;
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(AdditionalOptions);