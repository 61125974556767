import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import './ProductSearch.css';

interface State {
    showSearchBox:boolean;
}

function mapStateToProps(state:State):State {
    const {showSearchBox} = state;
    return {
        showSearchBox
    };
}

interface Props {
    showSearchBox?:boolean;
}

const ProductSearch:React.FunctionComponent<Props> = (props:Props) => {
    const [showSearchBox, setShowSearchBox] = useState(props.showSearchBox);
    const [hasLoaded, setHasLoaded] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{

        if(hasLoaded){
            if(props.showSearchBox && searchContainerRef.current!.classList.contains('search-container-mobile')){
                searchContainerRef.current!.classList.remove('search-container-mobile');
            } else if(props.showSearchBox && !searchContainerRef.current!.classList.contains('search-container-mobile')){
                searchContainerRef.current!.classList.add('search-container-mobile');
            } else if(!props.showSearchBox && searchContainerRef.current!.classList.contains('search-container-mobile')){
                searchContainerRef.current!.classList.remove('search-container-mobile');
            } else if(!props.showSearchBox && !searchContainerRef.current!.classList.contains('search-container-mobile')){
                searchContainerRef.current!.classList.add('search-container-mobile');
            }
        } else {
            setHasLoaded(true);
        }

        setShowSearchBox(props.showSearchBox);
    },[props.showSearchBox]);

    useEffect(()=>{
        window.addEventListener('resize', resizeHandler);
        return cleanupResizeHandler;
    },[]);

    function resizeHandler(e:UIEvent):void {
        if(window.matchMedia('(min-width:769px)').matches){
            //console.log('resize event');
            searchContainerRef.current!.classList.remove('search-container-mobile');
        }
    }

    function cleanupResizeHandler():void {
        window.removeEventListener('resize', resizeHandler);
    }

    return (
        <div className="left-col">
            <div ref={searchContainerRef} className="search-container">
                <form className="search-box-form">
                    <input className="form-search-box" type="text" placeholder="What can we help you find?"/>
                    <button className="form-search-box-btn" data-testid="form-search-box-btn" type="button">
                        <span className="search-text-desktop">Search</span>
                        <span className="search-icon-container-mobile">
                            <i className="fa fa-search sign-in-icon-mobile" aria-hidden="true"></i>
                        </span>
                    </button>
                </form>
            </div>
        </div>
    );
  }
  
  //export default ProductSearch;
export default connect(
    mapStateToProps,
    null
)(ProductSearch);