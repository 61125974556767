import {ADD_OVERLAY, ADD_SEARCH_BOX} from './actionTypes';
import {Overlay, SearchBox} from './actions';

interface State {
    showOverlay:boolean;
    showSearchBox:boolean;
}

const initialState:State = {
    showOverlay:false,
    showSearchBox:true
};

export default function overlayReducer(state:State = initialState, action:any):State {
    switch(action.type){
        case ADD_OVERLAY:
            return {
                ...state,
                showOverlay: action.payload.showOverlay
            };
        case ADD_SEARCH_BOX:
            return {
                ...state,
                showSearchBox:action.payload.showSearchBox
            }
        default:
            return state;
    }
}