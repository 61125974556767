import React, {useRef, useEffect} from 'react'
import ProductSearchOptions from './ProductSearchOptions';
import TopNav from './TopNav';
import MobilePanel from './MobilePanel';
import {window} from './window';
import './Header.css';

interface Props {}

const Header:React.FunctionComponent<Props> = (props:Props)=>{
    const start = useRef(0);
    const hRef = useRef<HTMLDivElement>(null);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);

    function step(timestamp:DOMHighResTimeStamp):void {
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 300){
            f2.current = window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(f2.current);
            hRef.current!.classList.remove('hide');
        }
    }

    useEffect(()=>{
        f1.current = window.requestAnimationFrame(step);
        counter.current += 1;
        //console.log('c2:', counter.current);
    },[]);


    return <div ref={hRef} data-testid="header" className="header hide">
                <ProductSearchOptions/>
                <TopNav/>
                <MobilePanel/>
            </div>
}

export default Header;