import React, {useState} from 'react';
import {NavLayout, Detail, RelatedLink} from './navLayout';
import './DetailNav.css';

interface Props{
    layout:NavLayout;
}

const DetailNav:React.FunctionComponent<Props> = (props:Props)=> {
    const [layout, setLayout] = useState<NavLayout>(props.layout);

    function setupRelatedLinks(relatedLinks:RelatedLink[]):JSX.Element{
        return <ul className="support-section-list detail-nav-link-container">
                    {relatedLinks.map((relatedLink, i)=>{
                        return <li key={i} className="support-section-item">
                                    <a key={i} href="/" className="support-section-item-link" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                        {relatedLink.linkName}
                                    </a>
                                </li>
                    })}
                </ul>
    }

    return (
            <div className="detail-nav-container">
                <div className="detail-nav-icon-container">
                    <img src={`${(layout.layout as Detail).image}`} data-testid="detail-nav-image" className="detail-nav-icon"/>
                    {/*<img src={`${(layout.layout as Detail).image}`} data-testid="detail-nav-image" className="detail-nav-icon"/>*/}
                </div>
                <h2 className="detail-nav-tagline">{(layout.layout as Detail).tagline}</h2>
                <p className="detail-nav-text">{(layout.layout as Detail).text}</p>
                {setupRelatedLinks((layout.layout as Detail).relatedLinks)}
            </div>
    );

}

export default DetailNav;
