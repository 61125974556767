import React, {useRef, useEffect} from 'react';
import ImageLoader from './ImageLazyLoader';
import ImageLazyLoader from './ImageLazyLoader';
import {window} from './window';
import './Promotion.css';
//<ImageLazyLoader type={'component'} >
//</ImageLazyLoader>
//srcSet="http://localhost:7000/img5-1700x662.jpg 1400w, http://localhost:7000/img5-1700x662-crop.jpg 1200w, http://localhost:7000/img5-1700x662-crop2.jpg 800w"
interface Props{}

const Promotion:React.FunctionComponent<Props> = (props:Props)=> {
    const start = useRef(0);
    const pRef = useRef<HTMLDivElement>(null);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);

    function step(timestamp:DOMHighResTimeStamp):void {
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 300){
            f2.current = window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(f2.current);
            pRef.current!.classList.remove('hide');
        }
    }

    useEffect(()=>{
        f1.current = window.requestAnimationFrame(step);
        //window.setTimeout(step2, 300);
        counter.current += 1;
        //console.log('c3:', counter.current);
    },[]);


    return (
            <div ref={pRef} className="middle-container hide">
                <div className="promotion-image-container">
                    <picture>
                        <source media="(min-width:769px)" srcSet="promotions/promo2-1600.webp 1600w, promotions/promo2-1200.webp 1200w, promotions/promo2-800.webp 800w"/>
                        {/*<source media="(min-width:769px)" srcSet="promotions/promo2-1600.webp 1600w, promotions/promo2-1200.webp 1200w, promotions/promo2-800.webp 800w"/>*/}
                        {/*<source media="(min-width:300px)" srcSet="promotions/promo2-vertical-800.webp 800w"/>*/}
                        <source media="(min-width:300px)" srcSet="promotions/promo2-vertical-800.webp 800w"/>
                        {/*<img alt="YEAR-END SALE" data-testid="promotion-image" className="promotion-image" src="promotions/promo2-1600.webp" srcSet="promotions/promo2-1600.webp 1600w, promotions/promo2-1200.webp 1200w, promotions/promo2-800.webp 800w, promotions/promo2-400.webp 400w"/>*/}
                        <img alt="YEAR-END SALE" data-testid="promotion-image" className="promotion-image" src="promotions/promo2-1600.webp" srcSet="promotions/promo2-1600.webp 1600w, promotions/promo2-1200.webp 1200w, promotions/promo2-800.webp 800w, promotions/promo2-400.webp 400w"/>
                    </picture>
                </div>
                <div className="bf-container-wrap">
                    <div className="bf-container">
                        <div data-testid="promotion-text" className="bf-text-wrap">
                            <div className="bf-title">YEAR-END SALE</div>
                            <div className="bf-headline">A Powerful Reason to Refresh</div>
                            <div className="bf-promo-text">Minimize downtime while boosting productivity and security.</div>
                            <div className="bf-btn-container">
                                <button className="bf-btn" type="button">PC Deals</button>
                                <button className="bf-btn" type="button">Accessories Deals</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    );
}

export default Promotion;