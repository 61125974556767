import React, {useState, useEffect, useRef} from 'react';
import pcs, {ProductCategory} from './productCategory';
import {window} from './window';
import './ProductCategorySection.css';

interface Props{
    productCategorySectionRefs:React.RefObject<HTMLUListElement[]>;
}

const ProductCategorySection:React.FunctionComponent<Props> = (props:Props)=> {
    const {productCategorySectionRefs} = props;
    const [productCategories, setProductCategories] = useState<ProductCategory[]>(pcs);
    const productCategorySectionRef = useRef<HTMLUListElement>(null);
    const start = useRef(0);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);
    const [showSideBorders, setShowSideBorders ] = useState(false);

    function step(timestamp:DOMHighResTimeStamp):void {
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 500){
            f2.current = window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(f2.current);
            productCategorySectionRef.current!.classList.remove('hide');
            //makeSupportSectionSticky(productCategorySectionRef.current as HTMLUListElement);
        }
    }

    useEffect(()=>{
        f1.current = window.requestAnimationFrame(step);
        counter.current += 1;
        //console.log('c3:', counter.current);
        productCategorySectionRefs.current!.push(productCategorySectionRef.current!);
    },[]);

    function pchHandler(e:React.TransitionEvent):void {
        e.stopPropagation();
        if(e.target instanceof HTMLDivElement){
            //console.log('style 1-19:', window.getComputedStyle(e.target as HTMLDivElement).transform);
            const transform = window.getComputedStyle(e.target as HTMLDivElement).transform;
            if(transform !== 'none'){
                const [scaleX, skewY, skewX, scaleY, translateX, translateY] = window.getComputedStyle(e.target as HTMLDivElement).transform.slice(7, -1).split(',');
                //console.log('translateY:', translateY);
            }

        }
    }

    function pchcHandler(e:React.TransitionEvent):void {
        e.stopPropagation();
        if(e.target instanceof HTMLDivElement){
            //console.log('style 1-19:', window.getComputedStyle(e.target as HTMLDivElement).transform);
            const transform = window.getComputedStyle(e.target as HTMLDivElement).transform;
            if(transform !== 'none'){
                const [scaleX, skewY, skewX, scaleY, translateX, translateY] = window.getComputedStyle(e.target as HTMLDivElement).transform.slice(7, -1).split(',');
                //console.log('translateY:', translateY);
            }

        }
    }

    function me(e:React.PointerEvent):void{
        //console.log('pe:', e.pointerType);
        e.stopPropagation();
        if(e.pointerType === 'mouse'){
            const pc = (e.target as HTMLElement).closest('.product-category');
            const pch = pc!.firstElementChild as HTMLDivElement;
            const pchc = (pch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
    
            if(pch.closest('.product-category-heading')){
                pc!.classList.add('side-borders');
                pch.classList.add('move-up-heading');
                pchc.classList.add('dark-shadow');
                return;
            } else {
                pc!.classList.add('side-borders');
                pch.classList.add('light-bg-color');
                pchc.classList.add('blue-shadow');
                return;        
            }
        }

    }

    function me2(e:React.MouseEvent):void{
        //console.log('pe:', e.pointerType);
        e.stopPropagation();
        //if(e.pointerType === 'mouse'){
            const pc = (e.target as HTMLElement).closest('.product-category');
            const pch = pc!.firstElementChild as HTMLDivElement;
            const pchc = (pch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
    
            if(pch.closest('.product-category-heading')){
                pc!.classList.add('side-borders');
                pch.classList.add('move-up-heading');
                pchc.classList.add('dark-shadow');
                return;
            } else {
                pc!.classList.add('side-borders');
                pch.classList.add('light-bg-color');
                pchc.classList.add('blue-shadow');
                return;        
            }
        //}

    }

    function ml(e:React.PointerEvent):void{
        //console.log('pl');
        e.stopPropagation();
        if(e.pointerType === 'mouse'){
            const pc = (e.target as HTMLElement).closest('.product-category');
            const pch = pc!.firstElementChild as HTMLDivElement;
            const pchc = (pch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
            if(pch.closest('.product-category-heading')){
                pc!.classList.remove('side-borders');
                pch.classList.remove('move-up-heading');
                pchc.classList.remove('dark-shadow');
                return;
            } else {
                pc!.classList.remove('side-borders');
                pch.classList.remove('light-bg-color');
                pchc.classList.remove('blue-shadow');
                return;        
            }
        }
 
    }

    function ml2(e:React.MouseEvent):void{
        //console.log('pl');
        e.stopPropagation();
        //if(e.pointerType === 'mouse'){
            const pc = (e.target as HTMLElement).closest('.product-category');
            const pch = pc!.firstElementChild as HTMLDivElement;
            const pchc = (pch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
            if(pch.closest('.product-category-heading')){
                pc!.classList.remove('side-borders');
                pch.classList.remove('move-up-heading');
                pchc.classList.remove('dark-shadow');
                return;
            } else {
                pc!.classList.remove('side-borders');
                pch.classList.remove('light-bg-color');
                pchc.classList.remove('blue-shadow');
                return;        
            }
        //}
 
    }

    //function mup(e:React.PointerEvent):void{
    function mup(e:React.TouchEvent):void{
        //console.log('pup');
        e.stopPropagation();
        const pcSection = (e.target as HTMLElement).closest('ul');
        let pcs = pcSection!.querySelectorAll('li');
        //console.log('pcs:', pcs);
        const pc = (e.target as HTMLElement).closest('.product-category');
        const pch = pc!.firstElementChild as HTMLDivElement;
        const pchc = (pch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
        pcs.forEach((spc)=>{
            const spch = spc.firstElementChild as HTMLDivElement;
            const spchc = (spch.firstElementChild as HTMLDivElement).nextElementSibling as HTMLDivElement;
            if(spc !== pc && spch.closest('.product-category-heading')){
                //console.log('passed1');
                //pc.classList.remove('side-borders');
                spch.classList.remove('move-up-heading');
                spchc.classList.remove('dark-shadow');
                return;
            }

            if(spc !== pc && !spch.closest('.product-category-heading')){
                //console.log('passed2');
                //pc.classList.remove('side-borders');
                spch.classList.remove('light-bg-color');
                spchc.classList.remove('blue-shadow');
                return;  
            }
        });

        if(pch.closest('.product-category-heading')){
            pch.classList.add('move-up-heading');
            pchc.classList.add('dark-shadow');  
            return;

        } else {
            pch.classList.add('light-bg-color');
            pchc.classList.add('blue-shadow');
            return;
        }
    }

    function setupProductCategories(productCategories:ProductCategory[]):JSX.Element[] {
        return productCategories.map((pc, i)=>{
            //origin version with pointer events
            //return <li onPointerUp={mup} onPointerEnter={me} onPointerLeave={ml} key={i} className="product-category">
            return <li onTouchEnd={mup} onMouseEnter={me2} onMouseLeave={ml2} key={i} className="product-category">
                        <div onTransitionEnd={pchHandler} className={(pc.display === 'slide') ? 'product-category-heading' : 'product-category-heading-no-slide'}>
                            <div className={(pc.display === 'slide') ? 'category-icon-container' : 'category-icon-container-no-slide'}>
                                <i className={`fa ${pc.icon} category-icon`} aria-hidden="true"></i>
                                <img data-testid="product-category-icon" className="category-icon" src={`${pc.icon}`}/>
                                {/*<img data-testid="product-category-icon" className="category-icon" src={`${pc.icon}`}/>*/}
                            </div>
                            <div onTransitionEnd={pchcHandler} className={(pc.display === 'slide') ? 'product-category-heading-caption' : 'product-category-heading-caption-no-slide'}>{pc.pcn}</div>
                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-category-heading-item-link">
                                <div className="product-category-heading-item">For Home</div>
                                <div className="product-category-heading-item">For Business</div>
                            </a>
                        </div>
                    </li>
        });
    }

    return (
        <ul ref={productCategorySectionRef} data-testid="pcs" className="product-category-section hide">
            {setupProductCategories(productCategories)}
            {/*
            <li className="product-category">
                <div className="product-category-heading">
                    <div className="category-icon-container">
                        <i className="fa fa-laptop category-icon" aria-hidden="true"></i>
                    </div>
                    <div className="product-category-heading-caption">Laptops</div>
                    <a href="/" className="product-category-heading-item-link">
                        <div className="product-category-heading-item">For Home</div>
                        <div className="product-category-heading-item">For Business</div>
                    </a>
                </div>
            </li>
            <li className="product-category">
                <div className="product-category-heading">
                    <div className="category-icon-container">
                        <i className="fa fa-desktop category-icon" aria-hidden="true"></i>
                    </div>
                    <div className="product-category-heading-caption">DeskTops</div>
                    <a href="/" className="product-category-heading-item-link">
                        <div className="product-category-heading-item">For Home</div>
                        <div className="product-category-heading-item">For Business</div>
                    </a>
                </div>
            </li>
            <li className="product-category">
                <div className="product-category-heading-no-slide">
                    <a href="/" className="product-category-heading-link">
                        <div className="category-icon-container-no-slide">
                            <i className="fa fa-laptop laptop-icon category-icon" aria-hidden="true"></i>
                        </div>
                        <div className="product-category-heading-caption-no-slide">WorkStations</div>
                        <div className="product-category-heading-item">For Home</div>
                        <div className="product-category-heading-item">For Business</div>
                    </a>
                </div>
            </li>
            <li className="product-category">
                <div className="product-category-heading">
                    <div className="category-icon-container">
                        <i className="fa fa-television category-icon" aria-hidden="true"></i>
                    </div>
                    <div className="product-category-heading-caption">Monitors</div>
                    <a href="/" className="product-category-heading-item-link">
                        <div className="product-category-heading-item">For Home</div>
                        <div className="product-category-heading-item">For Business</div>
                    </a>
                </div>
            </li>
            <li className="product-category">                    
                <div className="product-category-heading-no-slide">
                    <div className="category-icon-container-no-slide">
                        <i className="fa fa-server category-icon" aria-hidden="true"></i>
                    </div>
                    <div className="product-category-heading-caption-no-slide">Servers</div>
                    <div className="product-category-heading-item">For Home</div>
                    <div className="product-category-heading-item">For Business</div>
                </div>
            </li>
            <li className="product-category">
                <div className="product-category-heading">
                    <div className="category-icon-container">
                        <i className="fa fa-headphones category-icon" aria-hidden="true"></i>
                    </div>
                    <div className="product-category-heading-caption">Electronics</div>
                    <a href="/" className="product-category-heading-item-link">
                        <div className="product-category-heading-item">For Home</div>
                        <div className="product-category-heading-item">For Business</div>
                    </a>
                </div>
            </li>*/}
        </ul>
    );
}

export default ProductCategorySection;