import React from 'react';
import ProductSearch from './ProductSearch';
import AdditionalOptions from './AdditionalOptions';
import './ProductSearchOptions.css';

interface Props {}

const ProductSearchOptions:React.FunctionComponent<Props> = (props:Props) => {
    return (
      <div className="product-search-options-container">
        <ProductSearch/>
        <AdditionalOptions/>
      </div>
    );
  }
  
  export default ProductSearchOptions;