import React, {useRef, useEffect} from 'react';
import Header from './Header';
import SupportDesk from './SupportDesk';
import Promotion from './Promotion';
import SliderWrap from './SliderWrap';
import {window} from './window';
import './App.css';

interface Props {}

const App:React.FunctionComponent<Props> = (props:Props) => {
  const spacerRef = useRef<HTMLDivElement>(null);
  const start = useRef(0);
  const f1 = useRef(0);
  const f2 = useRef(0);
  const counter = useRef(0);

  function step(timestamp:DOMHighResTimeStamp):void {
    //console.log('scroll');
    window.cancelAnimationFrame(f1.current);
      if(start.current === 0){
          start.current = timestamp;
      }
      //console.log('ts:', timestamp);
      //console.log('start:', start.current);
      const elapsed = timestamp - start.current;
      //console.log('elapsed:', elapsed);
      if(elapsed < 300){
          f2.current = window.requestAnimationFrame(step);
          counter.current += 1;
      } else {
          spacerRef.current!.classList.remove('hide');
          window.cancelAnimationFrame(f2.current);
          const top = window.sessionStorage.getItem('scrollTop');
          //console.log('top:', top);
          if(top !== null){
              window.scrollTo(0, window.parseInt(top));
          }

          //console.log('last counter:', counter.current);
      }
  }

  useEffect(()=>{
    f1.current = window.requestAnimationFrame(step);
    //counter.current += 1;
    //const top = window.sessionStorage.getItem('scrollTop');
    /*
    console.log('top:', top);
    if(top !== null){
      console.log('w.pi:', window.parseInt(top));
        window.scrollTo(0, window.parseInt(top));
    }
    */
    //console.log('c1:', counter.current);
    //console.log('sh:', window.innerHeight);
    //console.log('sw:', window.innerWidth);
    //console.log('f1:', f1.current);
  },[]);


  return (
    <div className="app-container">
      <Header/>
      <SupportDesk/>
      <Promotion/>
      <div ref={spacerRef} data-testid="spacer" className="spacer"></div>
      <SliderWrap/>
    </div>
  );
}

export default App;
