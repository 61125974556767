interface NavItem {
    navItemName:string;
    menuLayout:string;
    menuItems:MenuItem[];
}

export interface MenuItem {
    menuItemName:string;
    subMenuLayout:string;
    subMenuItems:SubMenuItem[];
}

export interface SubMenuItem {
    subMenuItemName:string;
    image?:string;
    caption?:string;
    subtitle?:string;
}

export const navItemList = [
    
            {
                navItemName:'Products',
                menuLayout:'adjust',
                menuItems:[
                    {
                        menuItemName:'Laptops',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/laptop-for-home.webp',
                                caption:'For Home',
                                subtitle:'View all Laptops for Home' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/laptop-for-business.webp',
                                caption:'For Business',
                                subtitle:'View all Laptops for Business' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Desktops & All-in-One PCs',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/dt-for-home.webp',
                                caption:'For Home',
                                subtitle:'View all Desktops & All-in-One PCs for Home' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/dt-for-business.webp',
                                caption:'For Business',
                                subtitle:'View all Desktops & All-in-One PCs for Business' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Tablets & 2-in-1 Laptops',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/t-for-home.webp',
                                caption:'For Home',
                                subtitle:'View all 2-in-1 Laptops for Home' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/t-for-business.webp',
                                caption:'For Business',
                                subtitle:'View all Tablets & 2-in-1 Laptops for Business' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Gaming',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/alien-gaming.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/dell-gaming.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Work Stations',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Thin Clients',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Servers & Storage',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Networking',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/net-for-home.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/net-for-business.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Monitors',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/monitor-for-home.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/monitor-for-business.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Printer, Ink & Toner',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/print-for-home.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/print-for-business.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    
                    {
                        menuItemName:'Software',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/soft-for-home.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/soft-for-business.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Electronics & Accessories',
                        subMenuLayout:'normal',
                        subMenuItems:[
                            {
                                subMenuItemName:'',
                                image:'nav-items/elect-for-home.webp',
                                caption:'Alienware PCs',
                                subtitle:'View all Gaming Laptops and Desktops' 
                            },
                            {
                                subMenuItemName:'',
                                image:'nav-items/elect-for-business.webp',
                                caption:'Dell Gaming',
                                subtitle:'View all Gaming PCs, Consoles and Accessories' 
                            }
                        ]
                    },
                    {
                        menuItemName:'Gateways & Embedded PCs',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    }
                ]

            },
            {
                navItemName:'Solutions',
                menuLayout:'normal',
                menuItems:[
                    {
                        menuItemName:'View All Solutions',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Industries',
                        subMenuLayout:'adjust',
                        subMenuItems:[
                            {
                                subMenuItemName:'Federal Government'
                            },
                            {
                                subMenuItemName:'Healthcare'
                            },
                            {
                                subMenuItemName:'Higher Education'
                            },
                            {
                                subMenuItemName:'K-12 Education'
                            },
                            {
                                subMenuItemName:'Original Equipment Manufacturers'
                            },
                            {
                                subMenuItemName:'Retail'
                            },
                            {
                                subMenuItemName:'State & Local Government'
                            },
                            {
                                subMenuItemName:'Telecommunications, Media & Entertainment'
                            }
                        ]
                    },
                    {
                        menuItemName:'Small Business',
                        subMenuLayout:'adjust',
                        subMenuItems:[
                            {
                                subMenuItemName:'Small Business Solutions'
                            },
                            {
                                subMenuItemName:'Dell Expert Network'
                            },
                            {
                                subMenuItemName:'Dell Professional Associations & Non-Profits'
                            }
                        ]
                    },
                    {
                        menuItemName:'Big Data',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Cloud',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Data Center',
                        subMenuLayout:'adjust',
                        subMenuItems:[
                            {
                                subMenuItemName:'Data Center - Servers'
                            },
                            {
                                subMenuItemName:'Data Center - Storage'
                            },
                            {
                                subMenuItemName:'Data Center - Networking'
                            },
                            {
                                subMenuItemName:'Data Center - Modular Infrastructure'
                            },
                            {
                                subMenuItemName:'Converged Infrastructure'
                            },
                            {
                                subMenuItemName:'OpenManage Systems Management'
                            }

                        ]
                    },
                    {
                        menuItemName:'Dell Hybrid Client',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Internet of Things',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Pc as a Service (PcaaS) for Business',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Training & Certification',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Unified Workspace',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Virtual Desktop Infrastructure',
                        subMenuLayout:'adjust',
                        subMenuItems:[]
                    }
                ]
            },
            {
                navItemName:'Services',
                menuLayout:'normal',
                menuItems:[
                    {
                        menuItemName:'View All Services',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Warranty Extension',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Services for Home',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Services for Small Business',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Support Services for Commerical',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Consulting',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    },
                    {
                        menuItemName:'Managed Services',
                        subMenuLayout:'normal',
                        subMenuItems:[]
                    }
                ]
            },
            {
                navItemName:'Support',
                menuLayout:'normal',
                menuItems:[]
            },
            {
                navItemName:'Deals',
                menuLayout:'normal',
                menuItems:[]
            },
            {
                navItemName:'Financing',
                menuLayout:'normal',
                menuItems:[]
            }

        ];

export default NavItem;