import React, {useState, useRef, useEffect} from 'react';
import ProductSection from './ProductSection';
import ProductSectionNav from './ProductSectionNav';
import {Product} from './product';
import {NavLayout} from './navLayout';
import productSectionAreaLayouts, {ProductSectionAreaLayout} from './productSectionAreaLayout';
import './ProductSectionArea.css';


interface Props{
    productSectionContainers:React.RefObject<HTMLDivElement[]>;
}

const ProductSectionArea:React.FunctionComponent<Props> = (props:Props)=> {
    const {productSectionContainers} = props;
    const [productSectionAreaLayoutList, setProductSectionAreaLayouts] = useState<ProductSectionAreaLayout[]>(productSectionAreaLayouts);
    const productSectionContainerRefs = useRef<HTMLDivElement[]>([]);

    function setupProductSectionArea(psall:ProductSectionAreaLayout[]):JSX.Element[] {
        const sortedPsall = psall.sort((a, b)=>{
            if(a.type.rank < b.type.rank){
                return -1;
            } else if(a.type.rank > b.type.rank){
                return 1;
            } else {
                return 0;
            }
        });

        return sortedPsall.map((psall, i)=>{
                    if((psall.type as NavLayout).style === 'nav'){
                        return <ProductSectionNav key={i} navLayout={psall.type as NavLayout}/>
                    } else {
                        return <ProductSection key={i} product={psall.type as Product}
                                productSectionContainerRefs={productSectionContainerRefs}/>
                    }
                });

    }

    useEffect(()=>{
        productSectionContainers.current!.push(...productSectionContainerRefs.current);
    },[]);

    return (
        <div className="product-section-wrap">
            {setupProductSectionArea(productSectionAreaLayoutList)}
        </div>
    );
}

export default ProductSectionArea;