import navLayouts, {NavLayout} from './navLayout';
import products, {Product} from './product';

export interface ProductSectionAreaLayout {
    type:Product|NavLayout;
}

function setupProductSectionLayouts(products:Product[], navLayouts:NavLayout[]):ProductSectionAreaLayout[]{
    const ups = products.map((p)=>{
        return {type:p};
    });

    const unls = navLayouts.map((nl)=>{
        return {type:nl};
    });

    return [...ups, ...unls];
}

const productSectionAreaLayouts = setupProductSectionLayouts(products, navLayouts);

export default productSectionAreaLayouts;