import {ADD_OVERLAY , ADD_SEARCH_BOX} from './actionTypes';

export interface Overlay {
    type:string;
    payload:{
        showOverlay:boolean
    };
}

function addOverlay(showOverlay:boolean):Overlay {
    return {
        type:ADD_OVERLAY,
        payload:{
            showOverlay:showOverlay
        }
    };
}

export interface SearchBox {
    type:string;
    payload:{
        showSearchBox:boolean;
    };
}

export function addSearchBox(showSearchBox:boolean):SearchBox {
    return {
        type:ADD_SEARCH_BOX,
        payload:{
            showSearchBox:showSearchBox
        }
    };

}

export default addOverlay;