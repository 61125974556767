import React, {useState, useRef, useEffect, SyntheticEvent} from 'react';
import products, {Product, ProductLink} from './product';
import ImageLazyLoader from './ImageLazyLoader';
import * as _ from 'lodash';
import { window } from './window';
import './ProductSection.css';


interface Props{
    product:Product;
    productSectionContainerRefs:React.RefObject<HTMLDivElement[]>;
}

const ProductSection:React.FunctionComponent<Props> = (props:Props)=> {
    const {productSectionContainerRefs} = props;
    const [product, setProduct] = useState<Product>(props.product);
    const productImgContainerRef = useRef<HTMLDivElement>(null);
    const productContentContainerRef = useRef<HTMLDivElement>(null);
    const psRef = useRef<HTMLDivElement>(null);
    const start = useRef(0);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);

    //console.log('product:', product);

    function step(timestamp:DOMHighResTimeStamp):void {
        //console.log('scroll');
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 300){
             f2.current = window.requestAnimationFrame(step);
        } else {
            psRef.current!.classList.remove('hide');
            window.cancelAnimationFrame(f2.current);
            setupProductContainerMovement(productImgContainerRef.current as HTMLDivElement);
            setupProductContainerMovement(productContentContainerRef.current as HTMLDivElement);
        }
    }

    function setupProductLinks(productLinks:ProductLink[]):JSX.Element {
        return <div className="product-type-container">
                    {productLinks.map((pl, i)=>{
                        if(pl.position === 'single'){
                            return <a key={i} href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-type-link-single">
                                        {pl.productName}
                                    </a>                           
                        } else if(pl.position === 'left'){
                            return <a key={i} href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-type-link-left">
                                        {pl.productName}
                                    </a> 
                        } else {
                            return <a key={i} href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-type-link-right">
                                        {pl.productName}
                                    </a>                            
                        }
                    })}
                </div>

    }

    function setupProductContainerMovement(pic:HTMLDivElement):void {
        const eh = pic.getBoundingClientRect().top
        //console.log('eh:', eh);
        //console.log('w.ih:', window.innerHeight);
        if(!pic.classList.contains('move-container-up') && eh < window.innerHeight && window.matchMedia('(min-width:700px)').matches){
            pic.classList.add('move-container-up');

            
        }   
    }

    useEffect(()=>{    
        f1.current = window.requestAnimationFrame(step);
        counter.current += 1;
        //console.log('c5:', counter.current);
        productSectionContainerRefs.current!.push(productImgContainerRef.current!);
        productSectionContainerRefs.current!.push(productContentContainerRef.current!);     

    },[]);

    return (
            <div ref={psRef} className={(product.layout === 'normal') ? 'product-section hide' : 'product-section-reverse hide'}>
                <div ref={productImgContainerRef} data-testid="pic" className="product-image-container" >
                    <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-image-container-link">
                        {/*<ImageLazyLoader type={'image'} triggerTop={-100} triggerBottom={10}>*/}
                            <img alt={product.altTag} data-testid="product-image" className="product-image" 
                            srcSet={product.wd}
                            src={`${product.image}`}
                            sizes={'(min-width:768px) 55vw, 100vw'}/>
                            {/*<img alt={product.altTag} data-testid="product-image" className="product-image" 
                            srcSet={product.wd}
                            src={`${product.image}`}
                            sizes={'(min-width:768px) 55vw, 100vw'}/>*/}
                        {/*</ImageLazyLoader>*/}
                    </a>
                </div>
                <div ref={productContentContainerRef} data-testid="pcc" className="product-content-container">
                    <div>
                        <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()} className="product-content-title-link">
                            <div>
                                <h2 className="product-content-title">{product.content.title}</h2>
                                <p className="product-content-tagline">{product.content.tagline}</p>
                            </div>
                        </a>
                        <p className="product-content-text">{product.content.text}</p>
                        <div className="product-type-container">
                            {setupProductLinks(product.content.productLinks)}
                        </div>
                    </div> 
                </div>
            </div>
    );
}

export default ProductSection;