import React, {useState} from 'react'
import NavItem, {MenuItem, SubMenuItem, navItemList} from './navItem';
import './MobilePanel.css';

interface Props {}

const MobilePanel:React.FunctionComponent<Props> = (props:Props)=>{
    const [navList, setNavList] = useState<NavItem[]>(navItemList);

    function dropdownListClickHandler(e:React.MouseEvent):void {
        //console.log('drop type:', e.type);
        e.stopPropagation();
        e.preventDefault();
        const anchorElement = e.target as HTMLAnchorElement;
        mobileDropdownListHandler(anchorElement);
    }

    function dropdownListTouchEndHandler(e:React.TouchEvent):void {
        //console.log('drop type:', e.type);
        console.log('e.cancelable:', e.cancelable);
        if(e.cancelable){
            e.preventDefault();
        }
        
        //previous version
        //const anchorElement = (e.changedTouches[0].target as HTMLAnchorElement);
        const anchorElement = (e.target as HTMLAnchorElement);
        mobileDropdownListHandler(anchorElement);
    }

    function mobileDropdownListHandler(anchorElement:HTMLAnchorElement):void {
        //console.log('type:', e.type);
        //e.stopPropagation();
        //e.preventDefault();
        //console.log('touches:', e.changedTouches[0].target);
        //console.log('touches:', e.changedTouches);
        //const anchorElement = (e.changedTouches[0].target as HTMLAnchorElement);
        const ulElement = anchorElement.nextElementSibling as HTMLUListElement;
        if(anchorElement.classList.contains('mobile-panel-nav-list-item-link-plain')){
            return;
        } else if(ulElement.classList.contains('hide-mobile-dropdown-list')
            && anchorElement.classList.contains('mobile-panel-nav-list-item-link-close')){
            anchorElement.classList.remove('mobile-panel-nav-list-item-link-close');
            anchorElement.classList.add('mobile-panel-nav-list-item-link-open');
            ulElement.classList.remove('hide-mobile-dropdown-list');
        } else {
            anchorElement.classList.add('mobile-panel-nav-list-item-link-close');
            anchorElement.classList.remove('mobile-panel-nav-list-item-link-open');
            ulElement.classList.add('hide-mobile-dropdown-list');
        }
        
        
    }

    function subDropdownListClickHandler(e:React.MouseEvent):void {
        //console.log('sub drop type:', e.type);
        e.stopPropagation();
        e.preventDefault();
        const anchorElement = e.target as HTMLAnchorElement;
        mobileSubDropdownListHandler(anchorElement);
    }

    function subDropdownListTouchEndHandler(e:React.TouchEvent):void {
        //console.log('sub drop type:', e.type);
        if(e.cancelable){
            e.preventDefault();
        }
        //const anchorElement = (e.changedTouches[0].target as HTMLAnchorElement);
        const anchorElement = e.target as HTMLAnchorElement;
        mobileSubDropdownListHandler(anchorElement);
    }

    function mobileSubDropdownListHandler(anchorElement:HTMLAnchorElement):void {
        //e.preventDefault();
        //console.log('touches:', e.changedTouches[0].target);
        //console.log('touches:', e.changedTouches);
        //const anchorElement = (e.changedTouches[0].target as HTMLAnchorElement);
        const ulElement = anchorElement.nextElementSibling as HTMLUListElement;
        if(ulElement.classList.contains('hide-mobile-sub-dropdown-list')
            && anchorElement.classList.contains('mobile-panel-dropdown-nav-list-item-link-close')){
            anchorElement.classList.remove('mobile-panel-dropdown-nav-list-item-link-close');
            anchorElement.classList.add('mobile-panel-dropdown-nav-list-item-link-open');
            ulElement.classList.remove('hide-mobile-sub-dropdown-list');
        } else {
            anchorElement.classList.add('mobile-panel-dropdown-nav-list-item-link-close');
            anchorElement.classList.remove('mobile-panel-dropdown-nav-list-item-link-open');
            ulElement.classList.add('hide-mobile-sub-dropdown-list');
        }
        
    }

    function setupNav(navList:NavItem[]):JSX.Element {
        return <ul className="mobile-panel-nav-list">
                    {navList.map((item, i)=>{
                        return <li className="mobile-panel-nav-list-item" key={i} >
                                    <a href="/" 
                                        className={item.menuItems.length >0 ? 'mobile-panel-nav-list-item-link-close' : 'mobile-panel-nav-list-item-link-plain'} 
                                        onClick={dropdownListClickHandler}
                                        onTouchEnd={dropdownListTouchEndHandler} >
                                        {item.navItemName}
                                    </a>
                                    {setupDropdownMenu(item.menuItems)}
                                </li>
                    })}
                </ul>
    }

    function setupDropdownMenu(dropdownList:MenuItem[]):JSX.Element | null {
        //console.log('ml:', (menuLayout === 'adjust') ? 'menu-adjust' : '');
        if(dropdownList.length > 0){
            return <ul className="mobile-panel-dropdown-nav-list hide-mobile-dropdown-list">
                        {dropdownList.map((item, i)=>{
                            if(item.subMenuItems.length > 0){
                                return <li key={i} className="mobile-panel-dropdown-nav-list-item">
                                             <a href="/" className="mobile-panel-dropdown-nav-list-item-link-close" 
                                                onClick={subDropdownListClickHandler}
                                                onTouchEnd={subDropdownListTouchEndHandler}>
                                                {item.menuItemName}
                                            </a>
                                            {setupDropdownSubMenu(item.subMenuItems)}
                                        </li>
                            } else {
                                return <li key={i} className="mobile-panel-dropdown-nav-list-item">
                                             <a href="/" className="mobile-panel-dropdown-nav-list-item-link-plain">
                                                {item.menuItemName}
                                            </a>
                                        </li> 
                            }

                        })}
                    </ul>
        } else {
            return null;
        }

    }

    function setupDropdownSubMenu(subMenuList:SubMenuItem[]):JSX.Element | null {
        if(subMenuList.length > 0){
            return <ul className="mobile-panel-sub-dropdown-nav-list hide-mobile-sub-dropdown-list">
                        {subMenuList.map((item, i)=>{
                            if(item.subMenuItemName.length === 0){
                                return <li key={i} className="mobile-panel-sub-dropdown-nav-list-item">
                                            <a href="/" className="mobile-panel-sub-dropdown-nav-list-item-link">
                                                {item.caption}
                                            </a>
                                        </li>
                            } else {
                                return <li key={i} className="mobile-panel-sub-dropdown-nav-list-item">
                                            <a href="/" className="mobile-panel-sub-dropdown-nav-list-item-link">
                                                {item.subMenuItemName}
                                            </a>
                                        </li>
                            }
                            
                        })}
                    </ul>
        } else {
            return null;
        }

    }

    return (
        <div className="mobile-panel-container">
            <div className="mobile-panel-nav-container">
                {setupNav(navList)}
            </div>
        </div>

    );
}

export default MobilePanel;