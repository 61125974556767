export interface Product {
    rank:number;
    id:number;
    style:string;
    layout:string;
    image:string;
    altTag:string;
    wd?:string;
    content:ProductContent;
}

export interface ProductContent {
    title:string;
    tagline:string;
    text:string;
    productLinks:ProductLink[];
}

export interface ProductLink {
    productName:string;
    position:string;
}


const products = [
    {
        rank:1,
        id:1,
        style:'product',
        layout:'normal',
        image:'laptops/laptop-1200.webp',
        altTag:'Inspiron Laptops',
        //wd:'laptops/laptop-1200.webp 1200w, laptops/laptop-800.webp 800w, laptops/laptop-640.jpg 400w' ,
        wd:'',
        content:{
            title:'INSPIRON LAPTOPS & ALL-IN-ONES',
            tagline:'Stylishly Designed',
            text:'Connect to your world and immerse yourself in beautiful visuals.',
            productLinks:[
                {
                    productName:'Inspiron Laptops',
                    position:'left'
                },
                {
                    productName:'Inspiron All-In-Ones',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:2,
        id:2,
        style:'product',
        layout:'reverse',
        image:'laptops/latitude-2000.webp',
        altTag:'Latitude Laptops',
        //image:'img2-2000.jpg',
        wd:'' , 
        content:{
            title:'LATITUDE LAPTOPS & 2-IN-1 PCS',
            tagline:'Faster. Smarter. More Responsive',
            text:'The world\'s most intelligent PCs* with Dell Optimizer, built-in AI that adapts to how you work, wherever you are.',
            productLinks:[
                {
                    productName:'Shop Now',
                    position:'left'
                },
                {
                    productName:'Meet Precision',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:4,
        id:3,
        style:'product',
        layout:'normal',
        image:'laptops/alien-2000.webp',
        altTag:'Gaming Laptops',
        //image:'img3-2000.jpg',
        wd:'' ,
        content:{
            title:'BLACK FRIDAY',
            tagline:'Save On Legendary Wins',
            text:'Give yourself unrivaled gaming power.  Now for significantly less.',
            productLinks:[
                {
                    productName:'PC Deals',
                    position:'left'
                },
                {
                    productName:'Electronic Deals',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:5,
        id:4,
        style:'product',
        layout:'reverse',
        image:'servers/server-2000.webp',
        altTag:'Servers, Storage and Networking',
        //image:'img4-2000.jpg',
        wd:'',
        content:{
            title:'SERVERS, STORAGE, NETWORKING',
            tagline:'Flexible, Scalable IT Solutions',
            text:'Power transformation with server, storage and network solutions that adapt and scale to your business needs.',
            productLinks:[
                {
                    productName:'Shop Servers',
                    position:'left'
                },
                {
                    productName:'Shop Storage',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:7,
        id:5,
        style:'product',
        layout:'normal',
        image:'monitors/monitor-2000.webp',
        altTag:'Monitors',
        //image:'img5-2000.jpg',
        wd:'' , 
        content:{
            title:'BLACK FRIDAY',
            tagline:'Save On Top Tech',
            text:'Monitors engineered to impress and empower, now up to 40% off.',
            productLinks:[
                {
                    productName:'For Home',
                    position:'left'
                },
                {
                    productName:'For Business',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:8,
        id:6,
        style:'product',
        layout:'reverse',
        image:'laptops/precision-2000.webp',
        altTag:'Precision Laptops',
        //image:'img6-2000.jpg',
        wd:'' ,
        content:{
            title:'PRECISION LAPTOPS',
            tagline:'As Powerful as It is Intelligent',
            text:'The Dell Precision 5750 is the world\'s most intelligent 17" mobile workstation with AI-based optimization.',
            productLinks:[
                {
                    productName:'Shop Now',
                    position:'left'
                },
                {
                    productName:'Learn More',
                    position:'right'
                }
            ]
        }
    },
    {
        rank:10,
        id:7,
        style:'product',
        layout:'normal',
        image:'desktops/desktop-2000.jpg',
        altTag:'Electronics & Accessories',
        //image:'img7-2000.jpg',
        wd:'' , 
        content:{
            title:'ELECTRONICS & ACCESSORIES',
            tagline:'PC Accessories & Top-Brand Electronics',
            text:'Find the latest PC Accessories and Electronics designed to enhance your everyday needs whether at home or on the go.',
            productLinks:[
                {
                    productName:'For Home',
                    position:'left'
                },
                {
                    productName:'For Business',
                    position:'right'
                }
            ]
        }
    }
    
    

];

export default products;

