import React, {useState, useEffect, useRef} from 'react';
import {Product} from './product';
import DetailNav from './DetailNav';
import NormalNav from './NormalNav';
import {NavLayout} from './navLayout';
import ImageLazyLoader from './ImageLazyLoader';
import {window} from './window';
import './ProductSectionNav.css';

interface Props{
    navLayout:NavLayout;
}

const ProductSectionNav:React.FunctionComponent<Props> = (props:Props)=> {
    const [navLayout, setLayout] = useState<NavLayout>(props.navLayout);
    const [showNavLayout, setShowNavLayout] = useState(false);
    const start = useRef(0);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);

    function step(timestamp:DOMHighResTimeStamp):void {
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 300){
            f2.current = window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(f2.current);
            setShowNavLayout(true);
        }
    }

    useEffect(()=>{
        f1.current = window.requestAnimationFrame(step);
        counter.current += 1;
        //console.log('c4:', counter.current);
        //window.setTimeout(step2, 300);

    },[]);

    if(showNavLayout){
        return (

                    <div className={`support-section ${navLayout.layout.tagline.toLowerCase()}`}>
                        {(navLayout.layout.type === 'normal') ? <NormalNav layout={navLayout}/> : <DetailNav layout={navLayout}/>}
                    </div>

                );
    } else {
        return null;
    }
    


}

export default ProductSectionNav;
