import React, {useRef, useEffect} from 'react';
import './SupportDesk.css';

interface Props{}

const SupportDesk:React.FunctionComponent<Props> = (props:Props)=> {
    const supportDeskRef = useRef<HTMLDivElement>(null);
    const start = useRef(0);
    const f1 = useRef(0);
    const f2 = useRef(0);
    const counter = useRef(0);
    const supportDeskContainerRef = useRef<HTMLDivElement>(null);

    function closeSupportDesk(e:React.MouseEvent):void {
        const sd = supportDeskRef.current as HTMLDivElement
        if(window.matchMedia('(min-width:992px').matches){
            sd.classList.remove('open-support-desk-right');
            sd.classList.add('close-support-desk-right');        

        } else {
            sd.classList.remove('open-support-desk-bottom');
            sd.classList.add('close-support-desk-bottom');
        }

    }

    function openSupportDesk(e:React.MouseEvent):void {
        const sd = supportDeskRef.current as HTMLDivElement
        e.preventDefault();
        if(window.matchMedia('(min-width:992px').matches){
            sd.classList.remove('close-support-desk-right');
            sd.classList.add('open-support-desk-right');

        } else {
            sd.classList.remove('close-support-desk-bottom');
            sd.classList.add('open-support-desk-bottom');
        }

    }

    function step(timestamp:DOMHighResTimeStamp):void {
        window.cancelAnimationFrame(f1.current);
        if(start.current === 0){
            start.current = timestamp;
        }

        const elapsed = timestamp - start.current;
        //console.log('elapsed:', elapsed);
        if(elapsed < 300){
            f2.current = window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(f2.current);
            supportDeskContainerRef.current!.classList.remove('hide');
        }
    }

    useEffect(()=>{
        f1.current = window.requestAnimationFrame(step);
        counter.current += 1;
        //console.log('c2:', counter.current);
    },[]);

    return (
        <div ref={supportDeskContainerRef} className="sd-container hide">
            <a href="/" className="support-desk-handle-link-mobile"
                onClick={openSupportDesk}>
                <i className="fa fa-comment-o support-desk-icon" aria-hidden="true"></i>
            </a>
            <div ref={supportDeskRef} className="support-desk">
                <a href="/" className="support-desk-handle-link"
                    onClick={openSupportDesk}>
                    <i className="fa fa-comment-o support-desk-icon" aria-hidden="true"></i>
                    {/*<div className="support-desk-handle-text">We're here for you</div>*/}
                    <div className="support-desk-handle-text">Chat or Call</div>
                </a>
                <div className="support-desk-content">
                    <button type="button" className="support-desk-close-btn"
                        onClick={closeSupportDesk}>
                        <span className="support-desk-close-x">&times;</span>
                    </button>
                    <div className="support-desk-content-message">Need help? We're here for you.</div>
                    <ul className="support-desk-content-list">
                        <li>
                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                <span className="support-desk-icon-container"><i className="fa fa-comment-o support-desk-list-icon" aria-hidden="true"></i></span>
                                <span className="support-desk-content-list-item-text">Click to Chat</span>
                            </a>
                        </li>
                        <li>
                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                <span className="support-desk-icon-container"><i className="fa fa-phone support-desk-list-icon" aria-hidden="true"></i></span>
                                <span className="support-desk-content-list-item-text">Call 1-800-433-2392</span>
                            </a>
                        </li>
                        <li>
                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                <span className="support-desk-icon-container"><i className="fa fa-envelope-o support-desk-list-icon" aria-hidden="true"></i></span>
                                <span className="support-desk-content-list-item-text">Email Us</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SupportDesk;
