import React, {useState} from 'react';
import {NavLayout, RelatedLink, Normal} from './navLayout';
import './NormalNav.css';

interface Props{
    layout:NavLayout;
}

const NormalNav:React.FunctionComponent<Props> = (props:Props)=> {
    const [layout, setLayout] = useState<NavLayout>(props.layout);

    function setupRelatedLinks(relatedLinks:RelatedLink[]):JSX.Element{
        return <ul className="support-section-list">
                    {relatedLinks.map((relatedLink, i)=>{
                        return <li key={i} className="support-section-item">
                                    <a key={i} href="/" className="support-section-item-link" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                        {relatedLink.linkName}
                                    </a>
                                </li>
                    })}
                </ul>
    }

    return (
            <div className="normal-nav-container">
                <div>
                    <h2 className="support-section-title">{(layout.layout as Normal).tagline}</h2>
                    {setupRelatedLinks((layout.layout as Normal).relatedLinks)}
                </div>
            </div>
    );

}

export default NormalNav;
