import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import addOverlay, {Overlay} from './redux/actions';
import NavItem, {MenuItem, SubMenuItem, navItemList} from './navItem';
import './TopNav.css';

interface State {
    showOverlay:boolean;
}

function mapStateToProps(state:State):State {
    const {showOverlay} = state;
    return {
        showOverlay
    };
}

const mapDispatchToProps = { addOverlay };

interface Props {
    showOverlay:boolean;
    addOverlay:(showOverlay:boolean)=>Overlay;
}


const TopNav:React.FunctionComponent<Props> = (props:Props) => {
    //console.log('topNav showOverlay:', props.showOverlay);
    const {addOverlay} = props;
    const [navList, setNavList] = useState<NavItem[]>(navItemList);
    const [lastValidTopPosition, setLastValidTopPosition] = useState(0);
    //original version
    //const [limitReached, setLimitReached] = useState(false);
    const [showOverlay, setShowOverlay] = useState(props.showOverlay);
    const limitReached = useRef(false);
    const counter = useRef(0);
    const timeout = useRef(0);
    const se = useRef<{el:HTMLElement, hsm:boolean}[]>([]);
    const ct = useRef<HTMLLIElement|null>();
    const roddis = useRef<HTMLLIElement[]>([]);
    const ce = useRef<HTMLElement|null>();
    const smRefs = useRef<HTMLUListElement[]>([]);
    const dm = useRef<HTMLUListElement|null>();

    useEffect(()=>{
        setShowOverlay(props.showOverlay);
    },[props.showOverlay]);

    function getHeightAdjustment(list:HTMLLIElement[]):number {
        const hs = list.map((el)=>{
                        return el.getBoundingClientRect().height;
                    });

        return Math.min(...hs);
    }

    function getSubMenuRefs(el:HTMLUListElement):void {
        if(el instanceof HTMLUListElement){
            smRefs.current.push(el);
        }
    }

    
    function onEnter(ce:HTMLElement){
        //console.log('enter ce:', ce);
        //original version
        //ce.style.backgroundColor = '#cce7f3';
        ce.classList.add('show-dd-menu-item-bg-color');
        if(ce.lastElementChild!.matches('ul')){
            //original version
            //(ce.lastElementChild as HTMLUListElement).style.display = 'flex';
            (ce.lastElementChild as HTMLUListElement).classList.add('show-sub-dd-menu');
            const sdlt = window.parseInt(window.getComputedStyle(ce.lastElementChild!).height, 10);
            //console.log('sdlt:', sdlt);
            const ddis = Array.from(ce.parentElement!.children) as HTMLLIElement[];
            const roddis = [...ddis].reverse();
            //console.log('r:', roddis);
            const ha = getHeightAdjustment(roddis);
            //console.log('ha:', ha);
            let total = 0;
            let elPos = 0;
            let elTop = 0;
            let ses:HTMLLIElement[] = [];
            
            roddis.forEach((ddi, i)=>{
            //console.log('ddi:', ddi);
            //console.log('ddi:', ddi, 'ddi name:', ddi.firstElementChild.textContent);
            if(total < (sdlt - /*29*/ ha) ){
                //total += ddi.getBoundingClientRect().height;
                ///console.log('hr:', window.getComputedStyle(ddi).height);
                total += window.parseInt(window.getComputedStyle(ddi).height, 10);
                //console.log('t:', total);
                //console.log('ddi:', ddi);
                elPos = i;
                ses.push(ddi);
            }
            });
            
            const r = ses.some((el)=>{
                    //console.log('r:', el === ce);
                    return el === ce;
                    });
                    
            //ce.lastElementChild.style.display = 'flex';
        
            if(r){    
                //original version
                elTop = roddis[elPos].offsetTop;
                const diff = elTop - ce.offsetTop;
                (ce.lastElementChild as HTMLUListElement).style.top = `${diff}px`;
                //console.log('finish ce:', window.getComputedStyle(ce.lastElementChild!).top, 10);
            }
        }
    }

    function onEnter2(ce:HTMLElement){
        //console.log('enter ce:', ce);
        //original version
        //ce.style.backgroundColor = '#cce7f3';
        ce.classList.add('show-dd-menu-item-bg-color');
        if(ce.lastElementChild!.matches('ul')){
            //original version
            //(ce.lastElementChild as HTMLUListElement).style.display = 'block';
            (ce.lastElementChild as HTMLUListElement).classList.add('show-sub-dd-menu-adjust');
        }
    }
    
    function onLeave(ce:HTMLElement, rt:HTMLElement){
        //console.log('leave ce:', ce);
        if(rt.closest('.dropdown-menu-item') !== ce && ce.matches('.dropdown-menu-item')){
            //console.log('test2');
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            if(ce.lastElementChild!.matches('ul')){
                //original version
                //(ce.lastElementChild as HTMLUListElement).style.display = 'none';
                (ce.lastElementChild as HTMLUListElement).classList.remove('show-sub-dd-menu');
                (ce.lastElementChild as HTMLUListElement).classList.remove('show-sub-dd-menu-adjust');
            }
            return;
        }

        if(rt.closest('.dropdown-sub-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item')
            && (rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //console.log('test3');
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            return;
        }

        if(rt.closest('.dropdown-sub-menu-item-adjust') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item-adjust')
            && (rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //console.log('test3');
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            return;
        }

        if(rt.closest('.dropdown-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item')
            && (rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            //console.log('test4A');
            //const dmis = document.querySelectorAll('.dropdown-menu-item');
            /*
            const dmis = Array.from(ce.parentElement!.children) as HTMLLIElement[];
            dmis.forEach((dmi)=>{
                if(dmi !== rt.closest('.dropdown-menu-item') && (dmi as HTMLLIElement).lastElementChild!.matches('ul')){
                    ((dmi as HTMLLIElement).lastElementChild as HTMLUListElement).style.display = 'none';
                }
                
            });
            */
            smRefs.current.forEach((dsm)=>{
                if(rt.closest('.dropdown-menu-item')!.lastElementChild !== dsm){
                    //original version
                    //(dsm as HTMLUListElement).style.display = 'none';
                    (dsm as HTMLUListElement).classList.remove('show-sub-dd-menu');
                }
                
            });
            return;
        }

        if(rt.closest('.dropdown-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item-adjust')
            && (rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            //console.log('test4A');
            //const dmis = document.querySelectorAll('.dropdown-menu-item');
            /*
            const dmis = Array.from(ce.parentElement!.children) as HTMLLIElement[];
            dmis.forEach((dmi)=>{
                if(dmi !== rt.closest('.dropdown-menu-item') && (dmi as HTMLLIElement).lastElementChild!.matches('ul')){
                    ((dmi as HTMLLIElement).lastElementChild as HTMLUListElement).style.display = 'none';
                }
                
            });
            */
            smRefs.current.forEach((dsm)=>{
                if(rt.closest('.dropdown-menu-item')!.lastElementChild !== dsm){
                    //original version
                    //(dsm as HTMLUListElement).style.display = 'none';
                    (dsm as HTMLUListElement).classList.remove('show-sub-dd-menu-adjust');
                }
                
            });
            return;
        }

        if(rt.closest('.dropdown-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item')
            && !(rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //console.log('test4B');
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            //original version
            //const dsms = document.querySelectorAll('.dropdown-sub-menu');
            smRefs.current.forEach((dsm)=>{
            //dsms.forEach((dsm)=>{
                //original version
                //(dsm as HTMLUListElement).style.display = 'none';
                (dsm as HTMLUListElement).classList.remove('show-sub-dd-menu');
            });
            return;
        }

        if(rt.closest('.dropdown-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-sub-menu-item-adjust')
            && !(rt.closest('.dropdown-menu-item') as HTMLLIElement).lastElementChild!.matches('ul')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //console.log('test4B');
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            //const dsms = document.querySelectorAll('.dropdown-sub-menu-adjust');
            smRefs.current.forEach((dsma)=>{
            //dsms.forEach((dsm)=>{
                //original version
                //(dsma as HTMLUListElement).style.display = 'none';
                (dsma as HTMLUListElement).classList.remove('show-sub-dd-menu-adjust');
            });
            return;
        }

        //original version
        //if(rt.closest('.img-adjust') /*&& rt !== ce*/ && ce.matches('.dropdown-menu-item')){
        if(rt.closest('.dropdown-menu-item') /*&& rt !== ce*/ && ce.matches('.dropdown-menu-item')){
            //console.log('rt:', rt);
            //console.log('ce:', ce);
            //console.log('test5');
            //original version
            //ce.style.backgroundColor = '';
            ce.classList.remove('show-dd-menu-item-bg-color');
            return;
        }  
    
    }

    //onLeave2()

    function mouseover(e:React.MouseEvent, menuLayout:string):void {
        //console.log('ce:', ce.current);
        //console.log('target:', e.target);
        //console.log('mover ul:', (e.target as HTMLElement).closest('ul'));
        //console.log('mover ce:', ce.current);
        //console.log('mover ml:', menuLayout);
        if((e.target as HTMLElement).matches('ul')){return}
        const target = (e.target as HTMLElement).closest('li') as HTMLLIElement;
        //console.log('mover t:', target);
        if(ce.current === target){
            ce.current.classList.add('show-dd-menu-item-bg-color');
            return;
        }
        const ddul = (e.target as HTMLElement).closest('ul') as HTMLUListElement;
        //console.log('mover contains:', ddul.contains(target));
        if(!ddul.contains(target)){return}
        //original version
        //if(!ddRef.current!.contains(target)){return}
        if(ce.current !== target && menuLayout === 'adjust'){
            ce.current = target;
            onEnter(ce.current);
        } else if(ce.current !== target && menuLayout === 'normal'){
            ce.current = target;
            onEnter2(ce.current);
        }
        /*original version
        if(ce.current !== e.target && !(e.target as HTMLElement).matches('.dropdown-sub-menu')){
            const target = (e.target as HTMLElement).closest('li') as HTMLLIElement;
            if(!ddRef.current!.contains(target)){
                return;
            }
            ce.current = target;
            onEnter(ce.current);
        }
        */
    }

    function mouseout(e:React.MouseEvent):void {
        //console.log('mout dd:', (e.relatedTarget as HTMLElement));
        if(ce.current === null || ce.current === undefined){return}
        //const ddul = (e.relatedTarget as HTMLElement).closest('ul') as HTMLUListElement;
        //if(ddul === null || !ddul.contains(e.relatedTarget as HTMLElement)){
        if(e.relatedTarget === null || ((e.relatedTarget as HTMLElement).closest('.dropdown-menu') as HTMLUListElement) === null){
            //original version
            //console.log('test');
            //ce.current!.style.backgroundColor = '';
            ce.current!.classList.remove('show-dd-menu-item-bg-color');
            const dmi = ce.current!.closest('.dropdown-menu-item');
            if((dmi!.lastElementChild as HTMLElement).matches('ul')){
                //original version
                //(dmi!.lastElementChild as HTMLUListElement).style.display = 'none';
                (dmi!.lastElementChild as HTMLUListElement).classList.remove('show-sub-dd-menu');
                (dmi!.lastElementChild as HTMLUListElement).classList.remove('show-sub-dd-menu-adjust');
            }
            ce.current = null;
            return;
        }
        /*
        const ddul = (e.relatedTarget as HTMLElement).closest('.dropdown-menu') as HTMLUListElement;
        if(ddul === null){
        //original version
        //if(!ddRef.current!.contains(e.relatedTarget as HTMLElement)){
            ce.current!.style.backgroundColor = '';
            const dmi = ce.current!.closest('.dropdown-menu-item');
            if((dmi!.lastElementChild as HTMLElement).matches('ul')){
                (dmi!.lastElementChild as HTMLUListElement).style.display = 'none';
            }
            ce.current = null;
            return;
        }
        */

        if((e.relatedTarget as HTMLElement).closest('.dropdown-sub-menu-item-adjust') === null
            && (e.relatedTarget as HTMLElement).closest('.dropdown-sub-menu-adjust')){
                //original version
                //ce.current!.style.backgroundColor = '';
                ce.current!.classList.remove('show-dd-menu-item-bg-color');
                return;
        }

        if((e.relatedTarget as HTMLElement).matches('ul')){return}
        if(ce.current !== (e.relatedTarget as HTMLElement).closest('li') as HTMLLIElement){
            onLeave(ce.current!, e.relatedTarget as HTMLElement);
        }
    }

    function emptyPositionProductImageNavItem(e:React.MouseEvent):void {
        return;
    }

    /**set overlay if nav items are moused over **/
    function addNavOverlay(e:React.MouseEvent):void {
        //console.log('ano e:', (e.target as HTMLLIElement));// as HTMLElement).closest('.top-nav-list-item').firstElementChild);
        const target = (e.target as HTMLLIElement).firstElementChild as HTMLUListElement;
        if(target && target.matches('.dropdown-menu')){
            dm.current = target;
            dm.current.classList.add('show-dd-menu');
        }
        setShowOverlay(true);
        addOverlay(true);
    }

    function removeNavOverlay(e:React.MouseEvent):void {
        //console.log('rno e:', e.target);
        if(dm.current !== undefined){
            dm.current!.classList.remove('show-dd-menu');
        }
        
        /*
        const el = (e.target as HTMLLIElement).firstElementChild;
        if(el && el.matches('.dropdown-menu')){
            el.classList.remove('show-dd-menu');
        }
        */
        setShowOverlay(false);
        addOverlay(false);
    }

    function setupNav(navList:NavItem[]):JSX.Element {
        return <ul className="top-nav-list">
                    {navList.map((item, i)=>{
                        return <li className="top-nav-list-item" key={i}
                                    onMouseEnter={addNavOverlay} onMouseLeave={removeNavOverlay}>
                                    {item.navItemName}
                                    {setupDropdownMenu(item.menuItems, item.menuLayout)}
                                </li>
                    })}
                </ul>
    }

    function setupDropdownMenu(dropdownList:MenuItem[], menuLayout:string):JSX.Element | null {
        //console.log('ml:', (menuLayout === 'adjust') ? 'menu-adjust' : '');
        if(dropdownList.length > 0){
            return <ul data-testid="dm" className="dropdown-menu" onMouseOver={(e:React.MouseEvent)=>mouseover(e, menuLayout)} onMouseOut={mouseout}>
                        {dropdownList.map((item, i)=>{
                            if(item.subMenuItems.length > 0){
                                        //test version uses mouseover/mouseout on <ul/> element
                                        //original version was mouseEnter ans mouseoverHandler
                                        //test version was mouseenterHandler(e,true)
                                return <li /*onMouseEnter={(menuLayout === 'adjust') ? positionProductImageNavItem2 : emptyPositionProductImageNavItem}}*/
                                         className={(menuLayout === 'adjust') ? 'dropdown-menu-item img-adjust' : 'dropdown-menu-item'} key={i}>
                                             <a href="/" className="dropdown-menu-item-link" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                                <span className="dropdown-menu-item-name">{item.menuItemName}</span>
                                                <i className="fa fa-angle-right right-angle" aria-hidden="true"></i>
                                            </a>
                                            {setupDropdownSubMenu(item.subMenuItems, item.subMenuLayout)}
                                        </li>
                            } else {
                                return <li className={(menuLayout === 'adjust') ? 'dropdown-menu-item img-adjust' : 'dropdown-menu-item'} key={i}>
                                             <a href="/" className="dropdown-menu-item-link" onClick={(e:React.MouseEvent)=>e.preventDefault()}>
                                                <span className="dropdown-menu-item-name">{item.menuItemName}</span>
                                            </a>
                                        </li> 
                            }

                        })}
                    </ul>
        } else {
            return null;
        }

    }

    function setupDropdownSubMenu(subMenuList:SubMenuItem[], subMenuLayout:string):JSX.Element | null {
        if(subMenuList.length > 0){
                    //original event mouseenter and event listener mouseEnterSubMenuDropdownHandler
            return <ul ref={getSubMenuRefs} className={(subMenuLayout === 'normal') ? 'dropdown-sub-menu' : 'dropdown-sub-menu-adjust'}
                        >
                        {subMenuList.map((item, i)=>{
                            if(item.subMenuItemName.length === 0){
                                return <li key={i} 
                                        className={(subMenuLayout === 'normal') ? 'dropdown-sub-menu-item' : 'dropdown-sub-menu-item-adjust'}>
                                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()}
                                                className={(subMenuLayout === 'normal') ? 'sub-menu-item-link' : 'sub-menu-item-adjust-link'}
                                            >
                                                <div className="sub-menu-item-img-container">
                                                    <img src={`${item.image}`} alt="" width="115" height="75"/>
                                                    {/*<img src={`${item.image}`} alt="" width="115" height="75"/>*/}
                                                </div>
                                                <span className="sub-menu-item-caption">{item.caption}</span>
                                                <span className="sub-menu-item-subtitle">{item.subtitle}</span>
                                            </a>
                                        </li>
                            } else {
                                return <li key={i} 
                                            className={(subMenuLayout === 'normal') ? 'dropdown-sub-menu-item' : 'dropdown-sub-menu-item-adjust'}>
                                            <a href="/" onClick={(e:React.MouseEvent)=>e.preventDefault()}
                                                className={(subMenuLayout === 'normal') ? 'sub-menu-item-link' : 'sub-menu-item-adjust-link'}
                                            >
                                                <span className="dropdown-sub-menu-item-adjust-name">{item.subMenuItemName}</span>
                                            </a>
                                        </li>
                            }
                            
                        })}
                    </ul>
        } else {
            return null;
        }

    }



    return (
        <div>
            <div className="top-nav-container">
                {setupNav(navList)}
            </div>
            {showOverlay ?<div className="overlay"></div> : null}
        </div>

    );
  }
  
  //export default TopNav;
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(TopNav);