export interface NavLayout {
    rank:number;
    id:number;
    style:string;
    layout:Normal | Detail;
}

export interface Detail {
    type:string;
    image:string;
    tagline:string;
    text:string;
    relatedLinks:RelatedLink[];
}

export interface Normal {
    type:string;
    tagline:string;
    relatedLinks:RelatedLink[];
}

export interface RelatedLink {
    linkName:string;
}

const navLayouts = [
    {
        rank:3,
        id:8,
        style:'nav',
        layout:{
            type:'detail',
            image:'icons/shipping-icon.webp',
            tagline:'Fast-Shipping PCs',
            text:'Need your computer ASAP? Shop now for on-time holiday arrival. Select PCs ship within 1 business day.',
            relatedLinks:[
                {
                    linkName:'Shop for Home & Students'
                },
                {
                    linkName:'Shop for Business'
                }
            ]  
        }
 
    },
    {
        rank:6,
        id:9,
        style:'nav',
        layout:{
            type:'normal',
            tagline:'TECH SUPPORT',
            relatedLinks:[
                {
                    linkName:'Support Home'
                },
                {
                    linkName:'Drivers & Downloads'
                },
                {
                    linkName:'Order Support'
                },
                {
                    linkName:'Warranty & Contracts'
                }
            ] 
        }
  
    },
    {
        rank:9,
        id:10,
        style:'nav',
        layout:{
            type:'detail',
            image:'icons/gift-icon.webp',
            tagline:'2020 Holiday Gift Guide',
            text:'Find the perfect gift for everyone on your list.',
            relatedLinks:[
                {
                    linkName:'Gifts for Her'
                },
                {
                    linkName:'Gifts for Him'
                },
                {
                    linkName:'Gifts for Kids'
                },
                {
                    linkName:'Gifts for Gamers'
                },
                {
                    linkName:'All Gifts'
                }
            ] 
        }
  
    }
];

export default navLayouts;